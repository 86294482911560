import request from '@/utils/request'

// 会员中心
export function index(data) {
  return request({
    url: '/api/user/index',
    method: 'post',
    data,
  })
}
// 登录
export function login(data) {
  return request({
    url: '/api/user/login',
    method: 'post',
    data,
  })
}
// 注册
export function register(data) {
  return request({
    url: '/api/user/register',
    method: 'post',
    data,
  })
}
// 获取订单预参数
export function order_create(data) {
  return request({
    url: '/api/user/order_create',
    method: 'post',
    data,
  })
}
// 订单列表
export function order_list(data) {
  return request({
    url: '/api/user/order_list',
    method: 'post',
    data,
  })
}
// 订单下单
export function order_add(data) {
  return request({
    url: '/api/user/order_add',
    method: 'post',
    data,
  })
}
// 订单删除
export function order_del(data) {
  return request({
    url: '/api/user/order_del',
    method: 'post',
    data,
  })
}
