import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login'),
  },
  {
    path: '/index',
    name: 'index',
    redirect: '/order',
    component: () => import('@/views/index'),
    children: [
      {
        path: '/order',
        component: () => import('@/views/order'),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register'),
  }
]

// 防止连续点击多次路由报错
let routerPush = Router.prototype.push
let routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err)
}
// replace
Router.prototype.replace = function push(location) {
  return routerReplace.call(this, location).catch((err) => err)
}

const router = new Router({
  mode: 'hash',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

export default router
