import Vue from 'vue'

import Element from 'element-ui'
import '@/assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App.vue'
import store from './store'
import router from './router'
import plugins from './plugins' // plugins

import '@/assets/icons' // icon

import { resetForm } from '@/utils/ruoyi'

// 提交前需要注释  本地调试使用
// const vconsole = require('vconsole')
// Vue.prototype.$vconsole = new vconsole() // 使用vconsole

// 分页组件
import Pagination from '@/components/Pagination'
// 自定义表格工具组件
import RightToolbar from '@/components/RightToolbar'

// 全局方法挂载
Vue.prototype.resetForm = resetForm

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)

import jwx from '@/utils/jwx.js'
Vue.prototype.$jwx = jwx

const dayjs = require('dayjs')
Vue.prototype.$dayjs = dayjs

// 全局组件挂载
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)

store.dispatch('loadLocal')

Vue.use(Element, {
  size: 'medium', // set element-ui default size
})
Vue.use(plugins)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
